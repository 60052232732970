import React from 'react'
import Layout, { extractData, THEMES } from '../components/layout'
import { Cinema } from '../model/model';
import { graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import { Link } from '@reach/router';

const messages = [
  ["Dov'è la pagina, Lebowski? Dov'è la pagina?", "Delinquente Biondo", "Il Grande Lebowski (1998)"],
  ["Cosa c'è sulla pagina?", "Detective David Mills", "Se7en (1995)"],
  ["E' quella che dice 'Pagina non trovata'.", "Jules Winnfield", "Pulp Fiction (1994)"],
  ["Questa non è la pagina che stai cercando", "Obi-Wan", "Star Wars - Una Nuova Speranza (1977)"],
  ["Pagina non trovata? INCONCEPIBILE.", "Vizzini", "La Storia Fantastica (1987)"],
  ["Toto, ho l'impressione che non siamo più nel Kansas", "Dorothy", "Il Mago di Oz (1939)"],
  ["Pagine Web? Dove stiamo andando non c'è bisogno di pagine web.", "Dr. Emmett Brown", "Ritorno al Futuro (1985)"],
  ["Per quale masochistica ragione non ho scelto la pagina azzurra?", "Cypher", "Matrix (1999)"],
  ["Non cercare di piegare pagina. È impossibile. Cerca invece di fare l'unica cosa saggia: giungere alla verità.", "Bambino", "Matrix (1999)"]
]

function getQuote() {
  return messages[Math.floor(Math.random() * Math.floor(messages.length))]
}


const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 70vh;
  padding-top: 3rem;
`

const Comic = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-top: 2rem;
  margin-bottom: 5rem;
`

const Bubble = styled.div`
  min-height: 20vh;
  background-color: #FFDC00;
  width: 100%;
  color: white;
  font-size: 2rem;
  line-height: 3rem;
  padding: 15px;
`

const Arrow = styled.div`
  display: flex;
  width: 10px;
  height: 10px;
  margin-left: 150px;
  content: '';
  border-color: #FFDC00 transparent transparent transparent;
  border-style: solid;
  border-width: 21px 0px 0px 32px;
  height: 0;
  left: 700px;
  line-height: 0;
`

const Quote = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 90%;
`

const Author = styled.span`
  color: #9c9c9c;
`

const Film = styled.span`
  color: white;
  padding-left: 3px;
`

const Message = styled.div`
font-size: 1.3rem;
color: #9c9c9c;
text-align: center;

& p {
  margin-bottom: 0.5rem;
}
& a {
  color: white;
  text-decoration: underline;
}
`

interface PageProps {
  cinemas: Cinema[]
}
const NotFoundPage: React.SFC<PageProps> = ({cinemas}) => {
  const quote = getQuote()
  return (
    <Layout showBackButton={true} cinemas={cinemas} title="Pagina non trovata">
      <Page>
        <h1>404</h1>
        <Comic>
          <Bubble>
            {quote[0]}
          </Bubble>
          <Arrow/>
          <Quote>
            <Author>{quote[1]},</Author>
            <Film>{quote[2]}</Film>
          </Quote>
        </Comic>
        <Message>
          <p>La pagina che cercavi non è stata trovata. </p>
          <Link to="/">Torna alla homepage.</Link>
        </Message>
      </Page>
    </Layout>
  )
}

const StaticPage: React.SFC<any> = ( query ) => {
  const { cinemas, mainCinemaId } = extractData(query)

  return (
    <ThemeProvider theme={THEMES[mainCinemaId]}>
      <NotFoundPage cinemas={cinemas} />
    </ThemeProvider>
  )
}

export default StaticPage

export const query = graphql`
query NotFoundPageQuery {
  ...alldata
}
`